<style lang='scss'>
.contactform {
  position: relative;
  input {
    margin: 20px 0;
  }
  
  .button {
    border: none;
  }
  .submitt {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#fafafa,0.9);
  }
  .error {
    p {
      color: red;
    }
  }
}
</style>

<template>
<div class="contactform">
  <h1>Contact Form</h1>
  <div v-if="error" class="error">
    <p>There was an error sending the message, please contact us trough other channels while we fix the problem.</p>
  </div>
  <div v-else-if="success">
    <p>Message sent successfully!</p>
  </div>
  <b-form v-else @submit.prevent="onSubmit">
    <b-form-group
      id="input-group-1"
      label-for="input-1"
      >
      <b-form-input
        id="input-1"
        v-model="form.name"
        type="text"
        placeholder="Name"
        required
        ></b-form-input>
    </b-form-group>
    <b-form-group
      id="input-group-2"
      label-for="input-2"
      >
      <b-form-input
        id="input-2"
        v-model="form.email"
        type="email"
        placeholder="Email"
        required
        ></b-form-input>
    </b-form-group>
    <b-form-textarea
      id="textarea"
      v-model="form.message"
      placeholder="Enter something..."
      rows="3"
      max-rows="6"
      required
      ></b-form-textarea>
    <div class="submitt">
      <b-button type="submit" variant="primary button">SEND</b-button>
    </div>
  </b-form>
  <div v-if="loading" class="loading">
    <b-spinner></b-spinner>
  </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  data () {
    return {
      loading: false,
      success: false,
      error: false,
      form: {}
    }
  },
  methods: {
    onSubmit () {
      const csrftoken = this.getCookie('csrftoken')
      this.loading = true
      axios.post('/site/sendmail/', this.form, {
        headers: {
          'X-CSRFToken': csrftoken
        }
      })
        .then(response => {
          this.loading = false
          this.success = true
          console.log(response)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.error = true
        })
    },
    getCookie (name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    }
  }
}
</script>
