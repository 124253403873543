<style lang='scss'>
.messagebox {
  position: fixed;
  width: 100%;
  bottom: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  .container {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    max-width: 1200px;
    box-shadow: 5px 5px 15px #000;
    a {
      color: rgba(#000, 0.7);
      font-weight: bold;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>

<template>
<div v-if="show " class="messagebox">
  <div class="container">
    <h2>COVID-19</h2>
    <p>
      Team Buckley are an essential service and have been permitted to stay open for business during this current lockdown . Osteopathy & Physiotherapy in house treatments as well as online sessions are available to both existing and new clients. We look forward to seeing you.
    </p>
    <button class="button" @click="show = false">Close</button>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      show: true
    }
  }
}
</script>
