<style lang='scss'>
.questionnaire {
  .diffcol {
    background-color: rgba(0,0,0,0.05);
    padding: 15px 0;
    border-radius: 15px;
  }
  input, textarea {
    margin-bottom: 5px;
  }
  .row {
    margin-bottom: 10px;
  }
  .devider {
    text-align: center;
    padding: 20px;
  }
  label {
    margin: 10px 0;
  }
  .submitt-form {
    margin-top: 10px;
    text-align: center;
    button {
        margin-top: 10px;
      border: none;
      font-size: 18px;
    }
    input {
        margin-right: 5px;
    }
  }
  .custom-radio label {
    padding-left: 10px;
  }
  li {
    margin-top: 10px;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(1,1,1,0.8);
  }
  .paragraph {
      p {
          margin: 20px 0;
      }
  }
}
</style>

<template>
<div class="questionnaire">
  <div v-if="success" class="success">
    <p>The form has been submitted successfully!</p>
  </div>
  <b-form v-else @submit.prevent="onSubmit">
    <div class="row">
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-1"
          label="Firstname:"
          label-for="input-1"
          >
          <b-form-input
            id="input-1"
            v-model="formdata.firstname"
            type="text"
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-2"
          label="Surname:"
          label-for="input-2"
          >
          <b-form-input
            id="input-2"
            v-model="formdata.surname"
            type="text"
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-3"
          label="Date of Birth:"
          label-for="datepicker"
          >
          <b-form-datepicker id="datepicker" v-model="formdata.dateofbirth" :show-decade-nav="true" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en-GB" :required="true"></b-form-datepicker>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-4"
          label="Email:"
          label-for="input-4"
          >
          <b-form-input
            id="input-4"
            v-model="formdata.email"
            type="email"
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-8"
          label="Mobile phone number:"
          label-for="input-8"
          >
          <b-form-input
            id="input-8"
            v-model="formdata.mobilephone"
            type="tel"
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-18"
          label="Home phone number:"
          label-for="input-18"
          >
          <b-form-input
            id="input-18"
            v-model="formdata.homephone"
            type="tel"
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-5"
          label="Occupation:"
          label-for="input-5"
          >
          <b-form-input
            id="input-5"
            v-model="formdata.occupation"
            type="text"
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-6"
          label="Address:"
          label-for="input-6"
          >
          <b-form-input
            id="input-6"
            v-model="formdata.address"
            type="text"
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="column small-12 medium-4">
        <b-form-group
          id="input-group-7"
          label="Post code:"
          label-for="input-7"
          >
          <b-form-input
            id="input-7"
            v-model="formdata.zip"
            type="text"
            required
            ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="columns small-12 medium-6">
        <b-form-group
          id="input-group-9"
          label="Doctors name & address:"
          label-for="input-9"
          >
          <b-form-input
            id="input-9"
            v-model="formdata.doctor"
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="columns small-12 medium-6">
        <b-form-group
          id="input-group-10"
          label="Who referred you?"
          description="E.g, friend , GP , Consultant , Google, other please specify (if you know the name of the person who referred you please add this)"
          label-for="input-10"
          >
          <b-form-input
            id="input-10"
            v-model="formdata.referrer"
            type="text"
            ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row devider">
      <div class="columns medium-12">
        <strong>If applicable:</strong>
      </div>
    </div>
    <div class="row diffcol">
      <div class="columns small-12 medium-4">
        <b-form-group
          id="input-group-11"
          label="Brief details on the current injury area of concern:"
          label-for="input-11"
          description="e.g Low back , Knee , Hip etc"
          >
          <b-form-textarea
            id="input-11"
            v-model="formdata.injury.details"
            placeholder="..."
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="columns small-12 medium-4">
        <b-form-group
          id="input-group-12"
          label="If known when and how did the issue start?"
          label-for="input-12"
          >
          <b-form-textarea
            id="input-12"
            v-model="formdata.injury.start"
            placeholder="..."
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="columns small-12 medium-4">
        <b-form-group
          id="input-group-13"
          label="What makes it worse/better?"
          label-for="input-13"
          description="e.g medication , exercise, resting etc"
          >
          <b-form-textarea
            id="input-13"
            v-model="formdata.injury.change"
            placeholder="..."
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="columns small-12 medium-6">
        <b-form-group
          id="input-group-14"
          label="Previous medical history:"
          description="Please inform of any existing or pre-existing health concerns /injuries/ surgeries. We will ask you more in depth questions if necessary when you attend the clinic"
          label-for="input-14"
          >
          <b-form-textarea
            id="input-14"
            v-model="formdata.history"
            placeholder="..."
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="columns small-12 medium-6">
        <b-form-group
          id="input-group-15"
          label="Method of payment:"
          label-for="input-15"
          v-slot="{ ariaDescribedby }"
          >
          <b-form-radio
            v-model="formdata.selfpay"
            :aria-describedby="ariaDescribedby"
            name="radio-inline"
            value="Self-paying"
            required
            >Self-paying</b-form-radio>
          <b-form-radio
            v-model="formdata.selfpay"
            :aria-describedby="ariaDescribedby"
            name="radio-inline"
            value="Insurance"
            required
            >Insurance</b-form-radio>
        </b-form-group>
      </div>
      <div class="columns small-12 medium-12 paragraph">
        <p><i>If you are claiming through private health insurance (Osteopathy/Physiotherapy) please provide the insurers name , membership number & authorisation code. Please also check if you have an insurance excess as to avoid an unexpected bill a the end of your treatment. We are covered by all major insurers e.g Bupa /Axa PPP /AVIVA /WPA and more.</i></p>
      </div>
    </div>
    <div v-if="formdata.selfpay === 'Insurance'" class="row">
      <div class="columns small-12 medium-4">
        <b-form-group
          id="input-group-17"
          label="Insurance company:"
          label-for="input-17"
          >
          <b-form-input
            id="input-17"
            v-model="formdata.insurance_company"
            type="text"
            placeholder=""
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="columns small-6 medium-4">
        <b-form-group
          id="input-group-16"
          label="Authorisation number:"
          label-for="input-16"
          >
          <b-form-input
            id="input-16"
            v-model="formdata.authorisation_number"
            type="text"
            placeholder=""
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="columns small-6 medium-4">
        <b-form-group
          id="input-group-17"
          label="Policy number:"
          label-for="input-17"
          >
          <b-form-input
            id="input-17"
            v-model="formdata.policy_number"
            type="text"
            placeholder=""
            required
            ></b-form-input>
        </b-form-group>
      </div>
      <div class="columns medium-12">
      </div>
    </div>
    <div class="row devider">
      <div class="medium-12">
        <hr/>
      </div>
    </div>
    <div class="row">
      <div class="columns medium-12">
        <h3>Cancellations, no shows, and late arrivals policy</h3>
        <p>This limit the treatment we can offer and prevents our therapists from accommodating other clients who need to be seen. We appreciate that some clients will occasionally miss/forget/cancel appointments for genuine/unforeseen reasons. Therefore, this policy helps clarify our position going forward and supports  the therapists who may not get paid when an appointment is not kept. We would be grateful for your understanding and respect of our policy below.</p>
        <h3>Our Late cancellation or no-show policy is as follows</h3>
        <ul>
          <li>Greater than 24 hours’ notice no charge</li>
          <li><strong>Less than 24 hours full fee will be charged or loss of pre-paid session</strong></li>
          <li><strong>No shows 100% or loss of pre-paid session</strong></li>
        </ul>
        <h3>GDPR</h3>
        <p>
          Team Buckley Physio collects, stores and uses data in accordance with the General Data Protection Regulation (GDPR). Your information is restricted to Team Buckley staff (Secretarial and Clinical) and the clinics data protection officer. Your email address will be only used for communication only such as appointments and occasional clinic updates/news. Your information will not be stored for any other purpose.
        </p>
        <h3>Consent</h3>
        <p>
          I give my consent for treatment to carried out by my qualified therapist. I understand that to evaluate and treat my condition(s) my therapist must have visual or physical access to the area of the body being treated and/or associated to the problem. I understand it is my responsibility to communicate any difficulties or concerns I may have regarding my treatment.
        </p>
      </div>
    </div>
    <div class="row devider">
      <div class="medium-12">
        <hr/>
      </div>
    </div>
    <div class="submitt-form">
      <b-form-checkbox
        id="checkbox-1"
        v-model="formdata.consent"
        name="checkbox-1"
        value="accepted"
        required
        >
        I accept and have understood the above information.
      </b-form-checkbox>
      <b-button type="submit" variant="primary button">Submit</b-button>
    </div>
  </b-form>
  <div v-if="loading" class="loading">
    <b-spinner></b-spinner>
  </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  data () {
    return {
      loading: false,
      success: false,
      error: false,
      formdata: {
        injury: {}
      }
    }
  },
  methods: {
    onSubmit () {
      const csrftoken = this.getCookie('csrftoken')
      const self = this
      self.loading = true
      axios.post('/site/qn/', this.formdata, {
        headers: {
          'X-CSRFToken': csrftoken
        }
      })
        .then(response => {
          self.loading = false
          self.success = true
          console.log(response)
        })
        .catch(error => {
          console.log(error)
          self.loading = false
          self.error = true
        })
    },
    getCookie (name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    }
  }
}
</script>
