import "../styles/index.scss";

import $ from "jquery";
import Vue from 'vue';
import { BootstrapVue, IconsPlugin, FormDatepickerPlugin } from 'bootstrap-vue';
import Slideshow from './modules/slideshow2.js';
import VueVideoPlayer from 'vue-video-player';

import 'video.js/dist/video-js.css';

var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );
var Contactform = require('./modules/NewContact.vue');
var MessageBox = require('./modules/MessageBox.vue');
var Questionnaire = require('./modules/Questionnaire.vue');
var VideoPlayer = require('./modules/VideoPlayer.vue');
const google = window.google;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(VueVideoPlayer);


$(document).ready(function(){

	if ($('#app').length > 0) {
		Vue.component('contactform', Contactform.default);
		Vue.component('messagebox', MessageBox.default);
    Vue.component('questionnaire', Questionnaire.default);
    Vue.component('videoplayer', VideoPlayer.default);
		new Vue({
			el: '#app',
			delimiters: ["<%","%>"]
		});
	}

	if ($('.slideshow-container').length == 1) {
		var settings = {
			wrapper: $('.slider-wrapper'),
			slides: $(".slide"),
			previous: $(".slider-previous"),
			next: $('.slider-next'),
			index: 0,
			total: $(".slide").length,
			time: $(".slideshow-container").data("time"),
			dots: $('.dot'),
			autoplay: $('.slideshow-container').data('autoplay'),
			slideshowId: $('#slideshow-container'),
			menuItems: $('.mainmenuitem'),
			hasDotts: 1,
			isLink: 1
		};
		$('.slideshow-container').imagesLoaded( function(){
			Slideshow(settings);
		});

	}
	$(window).scroll(function() {
		if ($(document).scrollTop() > 50) {
			$('header').addClass('shrink');

		} else {
			$('header').removeClass('shrink');

		}
	});
	$(".hamburger").click(function(){
		$(this).toggleClass("is-active");
		$('.header-menu').toggleClass("show");
	});
	$(".header-menu a").click(function(){
		$('.header-menu').removeClass("show");
		$(".hamburger").removeClass("is-active");
	});

	$(".edit-button").on('click', function(e){
    console.log(e);
		var lineid = $(this).data("lineid");
		$("#"+lineid+" button").toggleClass('hide');
		$("#"+lineid+" .attribute-values").toggleClass('hide');
		$("#"+lineid+" .edit-card").toggleClass('show');
	});
	$(".cancel-button").on('click', function(e){
		e.preventDefault();
		var lineid = $(this).data("lineid");
		$("#"+lineid+" button").toggleClass('hide');
		$("#"+lineid+" .attribute-values").toggleClass('hide');
		$("#"+lineid+" .edit-card").toggleClass('show');
	});
	if ($('#map').length > 0) {
		var latitude = $('#map').data('latitude');
		var longitude = $('#map').data('longitude');
		initMap(latitude, longitude);
	}
	function initMap(latitude, longitude) {
		var map = new google.maps.Map(document.getElementById('map'), {
			center: {lat: latitude, lng: longitude},
			zoom: 13,
			disableDefaultUI: true,
			zoomControl: true,
			scrollwheel: false,
			styles: [ { "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" } ] }, { "elementType": "labels.icon", "stylers": [ { "visibility": "on" } ] }, { "elementType": "labels.text.fill", "stylers": [ { "color": "#616161" } ] }, { "elementType": "labels.text.stroke", "stylers": [ { "color": "#f5f5f5" } ] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [ { "color": "#bdbdbd" } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#eeeeee" } ] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [ { "color": "#757575" } ] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#e5e5e5" } ] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [ { "color": "#9e9e9e" } ] }, { "featureType": "road", "elementType": "geometry", "stylers": [ { "color": "#ffffff" } ] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [ { "color": "#757575" } ] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [ { "color": "#dadada" } ] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [ { "color": "#616161" } ] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [ { "color": "#9e9e9e" } ] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [ { "color": "#e5e5e5" } ] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [ { "color": "#eeeeee" } ] }, { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#c9c9c9" } ] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [ { "color": "#9e9e9e" } ] } ]

		});
		var marker = new google.maps.Marker({
			position: {lat: latitude, lng: longitude},
			title:"Hello World!"
		});
		marker.setMap(map);


		// var infowindow = new google.maps.InfoWindow();
		// var service = new google.maps.places.PlacesService(map);
		//
		// service.getDetails({
		//	 placeId: 'ChIJDcxNrBW23kcRKHFThWAX_Ug'
		// }, function(place, status) {
		//	 if (status === google.maps.places.PlacesServiceStatus.OK) {
		//	var marker = new google.maps.Marker({
		//		map: map,
		//		position: place.geometry.location
		//	});
		//
		//	 }
		// });
	}
	if ($('.treatments-page').length > 0) {
		$('.treatmentlink').on('click', function(){
			$('.treatmentlink').removeClass('selected');
			$(this).addClass('selected');
		});
		console.log("hei");
		$('.head').on("click", function(e){
			$('#markerbox').removeClass('whole');
			e.preventDefault();
			$('#markerbox').css({opacity: '1', top: '40px', left: '69px'});
		});
		$('.head').on("mouseleave", function(){

			// $('#markerbox').css({opacity: '0'})
		});
		$('.shoulder').on("click", function(e){
			$('#markerbox').removeClass('whole');
			e.preventDefault();
			$('#markerbox').css({opacity: '1', top: '60px', left: '20px'});
		});
		$('.shoulder').on("mouseleave", function(){

			// $('#markerbox').css({opacity: '0'})
		});
		$('.knee').on("click", function(){
			$('#markerbox').removeClass('whole');
			$('#markerbox').css({opacity: '1', top: '300px', left: '30px'});
		});
		$('.knee').on("mouseleave", function(){

			// $('#markerbox').css({opacity: '0'})
		});
		$('.foot').on("click", function(){
			$('#markerbox').removeClass('whole');
			$('#markerbox').css({opacity: '1', top: '400px', left: '35px'});
		});
		$('.foot').on("mouseleave", function(){

			// $('#markerbox').css({opacity: '0'})
		});
		$('.elbow').on("click", function(){
			$('#markerbox').removeClass('whole');
			$('#markerbox').css({opacity: '1', top: '140px', left: '5px'});
		});
		$('.elbow').on("mouseleave", function(){

			// $('#markerbox').css({opacity: '0'})
		});
		$('.hand').on("click", function(){
			$('#markerbox').removeClass('whole');
			$('#markerbox').css({opacity: '1', top: '210px', left: '-15px'});
		});
		$('.hand').on("mouseleave", function(){

			// $('#markerbox').css({opacity: '0'})
		});
		$('.hip').on("click", function(){
			$('#markerbox').removeClass('whole');
			$('#markerbox').css({opacity: '1', top: '210px', left: '40px'});
		});
		$('.hip').on("mouseleave", function(){

			// $('#markerbox').css({opacity: '0'})
		});
		$('.whole').on("click", function(){
			$('#markerbox').addClass('whole');
			$('#markerbox').css({opacity: '1', top: '0px', left: '0px'});
		});

	}
	$('.treatmentlink').on('click', function(e){
		e.preventDefault();
		var id = $(this).data('id');
		$('.treatment-item').css({
			'visibility': 'hidden',
			'opacity': '0',
		});
		console.log($('#'+id).height());
		var newheight = 90 + $('#'+id).height();
		$('.treatments-page').css('padding-bottom', newheight + 'px');
		$('#'+id).css({
			'visibility': 'visible',
			'opacity': '1',
		});
	});
	$.fn.filterByWidth = function() {
		var $images = $(".testimonial").filter(function() {
			return $(this).css('opacity') == 1;
		});
		return $images;
	};
	var test = $('.testimonial').filterByWidth(1);

	var test2 = ($(test).height() + 50 + 'px');
	console.log(test2);
	// $('.testimonials-detail').css('height', test2);
	$(window).on('load', function () {
		if ($('.testimonials-detail').length == 1) {
			var settings = {
				wrapper: $('.testimonials-detail'),
				slides: $(".testimonial"),
				previous: $(".slider-previous"),
				next: $('.slider-next'),
				index: 0,
				total: $(".testimonial").length,
				time: $(".testimonials-detail").data("time"),
				dots: $('.dot'),
				autoplay: $('.testimonials-detail').data('autoplay'),
				slideshowId: $('#testimonials-detail'),
				menuItems: $('.mainmenuitem'),
				setWrapperHeight: 1,
				hasDotts: 1
			};
			$('.testimonials-detail').imagesLoaded( function(){
				Slideshow(settings);

			});
		}
	});

});
