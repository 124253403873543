import $ from "jquery/dist/jquery.slim";
import Hammer from 'hammerjs';
export default function (config) {

		var s,
		Slideshow = {

			settings: {
				wrapper: $('.slider-wrapper'),
				slides: $(".slide"),
				previous: $(".slider-previous"),
				next: $('.slider-next'),
				index: 0,
				total: $(".slide").length,
				dots: $('.dot'),
				autoplay: $('.slideshow-container').data('autoplay')
			},

			init: function(config) {

				// kick things off
				s = config;

				s.swipe			= new Hammer.Swipe();
				s.hammer		= new Hammer.Manager(s.slideshowId[0], {
					touchAction: "pan-y",
				});
				s.hammer.add(s.swipe);

				s.play = Slideshow.playSlides();
				this.actions();
				if (s.setWrapperHeight == 1) {
						Slideshow.setWrapperHeight();
				}
				if (s.hasDotts == 1) {
						this.theDots();
				}


			},
			_slideTo: function( slide ) {
				var currentSlide = s.slides[slide];
				currentSlide.style.opacity = 1;

				for( var i = 0; i < s.slides.length; i++ ) {
					slide = s.slides[i];
					if( slide !== currentSlide ) {
						slide.style.opacity = 0;
					}
				}
				if (s.hasDotts == 1) {
						Slideshow.theDots();
				}
				if (s.setWrapperHeight == 1) {
						Slideshow.setWrapperHeight();
				}
			},
			actions: function() {
				s.hammer.on('swipeleft', function(){
					Slideshow.playSlides();
					Slideshow.nextSlide();
				});
				s.hammer.on('swiperight', function(){
					Slideshow.playSlides();
					Slideshow.previousSlide();
				});
				if (s.isLink == 1) {
						s.wrapper.on('click', function(){
							window.location.href = $(s.slides[s.index]).data('url');
						});
				}

				s.next.on( "click", function() {
					Slideshow.playSlides();
					Slideshow.nextSlide();
				});

				s.previous.on( "click", function() {

					Slideshow.playSlides();
					Slideshow.previousSlide();

				});
			},
			theDots: function() {
				Slideshow.menuHighlight();
				for (var i = 0; i < s.dots.length; i++) {
					s.dots[i].className = s.dots[i].className.replace(" active", "");
				}
				s.dots[s.index].className += " active";
				s.dots.on( "click", function(){
					s.index = $(this).data("id");
					Slideshow._slideTo(s.index);
					Slideshow.playSlides();
				});
			},
			nextSlide: function() {

				if( s.index == s.slides.length - 1 ) {
					s.index = 0;
				}
				else {
					s.index++;
				}

				Slideshow._slideTo( s.index );
			},
			previousSlide: function() {

				if( s.index == 0 ) {
					s.index = s.slides.length - 1;
				}
				else {
					s.index--;
				}
				Slideshow._slideTo( s.index );
			},
			playSlides: function() {
				if (s.autoplay == 'True') {
					clearInterval(s.play);

					s.play = setInterval(function(){
						Slideshow.nextSlide();
					},s.time);
					return s.play;
				}

			},
			menuHighlight: function() {
				var currentSlide = s.slides[s.index];
				$(s.menuItems).removeClass('highlighted');
				for (var i = 0; i < s.menuItems.length; i++) {
					if ($(currentSlide).data('sectionid') === $(s.menuItems[i]).data('sectionid')) {
							$(s.menuItems[i]).addClass('highlighted');
					}
				}

			},
			setWrapperHeight: function() {
					$(s.wrapper).css('height', ($(s.slides[s.index]).height() + 'px'));
			}

		};

		Slideshow.init(config);
 }
